.font-lg {
  font-size: 18px !important;
}
.font-em-2 {
  font-size: 2em;
  line-height: 1em;
}
.font-em-4 {
  font-size: 4em;
  line-height: 1em;
}
.font-em-10 {
  font-size: 10em;
  line-height: 1em;
}
.icon-circle {
  padding: 0.4em;
  border: 5px solid;
  border-radius: 50%;
}
.font-italic {
  font-style: italic;
}
.no-radius {
  border-radius: 0 !important;
}
.no-r-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.no-l-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.no-margin {
  margin:0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.no-border {
  border: none !important;
}
.no-box-shadow {
  box-shadow: none !important;
}
.no-padding {
  padding:0 !important;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}

.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-30 {
  padding: 30px !important;
}

.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
.padding-tb-15 {
  padding: 15px 0;
}
.vertical-centered-container{
  display: table;
  width:100%;
  height:100%;
}
.vertical-centered-wrapper{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.v_align_m{
  vertical-align:middle !important;
}
.v_align_t{
  vertical-align:top !important;
}
.v_align_b{
  vertical-align: bottom !important;
}
.counter{
  counter-reset: counter;
}
.counter_inc{
  counter-increment: counter;
}
.vertical-centered-inset{
  text-align: center;
  display: inline-block;
}
.line-through{
  text-decoration: line-through !important;
}
.text-no-transform {
  text-transform: none !important;
}
.lh_34{
  line-height:34px;
}
.lh_inherit {
  line-height: inherit !important;
}
.autoheight {
  height: auto;
}
.autowidth {
  width: auto;
}
.min-height-100 {
  min-height: 100px;
}
.min-width-100 {
  min-width: 100px;
}
.fullwidth {
  width: 100%;
}
.maxfullwidth {
  max-width: 100%;
}
.maxWidth250 {
  max-width: 250px;
}
.cursor:hover {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.word-break {
  word-break: break-all;
}
.relative {
  position: relative;
}
.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block !important;
}
.p_absolute {
  position: absolute !important;
}
.p_fixed {
  position: fixed !important;
}
.p_relative {
  position: relative !important;
}
.top_0 {
  top: 0 !important;
}
.top_10 {
  top: 10% !important;
}
.top_20 {
  top: 20% !important;
}
.top_30 {
  top: 30% !important;
}
.top_40 {
  top: 40% !important;
}
.top_50 {
  top: 50% !important;
}
.bottom_0 {
  bottom: 0 !important;
}
.bottom_5 {
  bottom: 5px !important;
}
.bottom_10 {
  bottom: 10px !important;
}
.bottom_20 {
  bottom: 20px !important;
}
.left_0 {
  left: 0 !important;
}
.right_0 {
  right: 0 !important;
}
.top_10px {
  top: 10px;
}
.top_20px {
  top: 20px;
}
.top_30px {
  top: 30px;
}
.top_40px {
  top: 40px;
}
.right_10px {
  right: 10px;
}
.right_20px {
  right: 20px;
}
.right_30px {
  right: 30px;
}
.right_40px {
  right: 40px;
}
.top_50 {
  top: 50% !important;
}
.ws-normal {
  white-space: normal !important;
}
.no-background {
  background: none !important;
}
.opacity-10{
  opacity: 0.1;
}
.opacity-25{
  opacity: 0.25;
}
.opacity-50{
  opacity: 0.5;
}
.opacity-75{
  opacity: 0.75;
}
.opacity-100{
  opacity: 1;
}
.overflow-hidden {
  overflow: hidden;
}
.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
}
.portlet {
  .portlet-title {
    .tools {
      .bootstrap-switch {
        margin-top: -6px;
      }
    }
  }
}
/* Cuborio Spinner
 UTILIZZO: <div class="load"><div class="blockcont"><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div></div></div>
 */
html {
  background:#222;
  padding:0;
  margin:0;
  height:100%;
}
.load {
  position:absolute;
  top:calc(50% - 30px);
  left:calc(50% - 30px);
  width:70px;
  height:70px;
}
.load-static {
  position: static;
  width:70px;
  height:70px;
}
.block {
  position:relative;
  height:20px;
  width:20px;
  display:inline-block;
  background:#bf2740;
  transition:all 0.8s;
  animation: rot 5s linear infinite;
}
.block:nth-child(1) {
  animation-delay:3s;
}
.block:nth-child(2) {
  animation-delay:1.5s;
  animation: rot 15s linear infinite;
}
.block:nth-child(3) {
  animation-delay:2s;
}
.block:nth-child(4) {
  animation-delay:0.2s;
}
.block:nth-child(5) {
  animation-delay:4s;
}
.block:nth-child(6) {
  animation-delay:2s;
  animation: rot 7s linear infinite;
}
.block:nth-child(7) {
  animation-delay:0.4s;
}
.block:nth-child(8) {
  animation-delay:1.5s;
  animation: rot 6s linear infinite;
}
.block:nth-child(9) {
  animation-delay:25s;
  animation: rot 8s linear infinite;
}

@keyframes rot {
  0% {
    transform:none;
  }
  20% {
    transform:rotateZ(-90deg) rotateY(180deg);
  }
  40% {
    background:#951c3f;
    transform:none;
  }
  60% {
    background:white
  }
  80% {
    background:#ee2640;
  }
  90% {
    transform:none;
    background:#222;
  }
}

/* Spinner per campi di Input
Utilizzo: <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
*/
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 7px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    margin: 2px;
    border: 1px solid #424242;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #424242 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.lds-for-input-lg {
    top: 13px;
    width: 28px;
    height: 28px;

    div {
      width: 24px;
      height: 24px;
      border: 2px solid #424242;
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
